<div class="shipping_row">
  <div col-12 no-padding class="shipping_col">
    <form #diffAddress="ngForm" class="shipping_from">

      <div class="form_item_wrapper">
        <label for="nameToShip" class="form_item_label">{{ getLabelShipping('shipToName')}}</label>

        <input
        data-qa="ship-name"
          fixScrollOnMobileiOS 
          id="nameToShip"
          name="nameToShip"
          #nameToShip="ngModel"
          [(ngModel)]="shipToName"
          (change)="bindShipToName(); remember_AccountInformation()"
          type="text"
          class="item_input"
          required
          maxlength="32"
          pattern="{{ textPattern }}"
        >
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && couponValidation && nameToShip.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>

      <div class="form_item_wrapper">
        <label for="shipAddress" class="form_item_label">{{ getLabelShipping('address')}}</label>

        <input
          fixScrollOnMobileiOS
          data-qa="ship-address1"
          id="shipAddress"
          name="shipAddress"
          #shipAddress="ngModel"
          [(ngModel)]="shippingAddress.street"
          type="text"
          class="item_input"
          required
          maxlength="150"
          pattern="{{ addressPattern }}"
          (change)="validate_AddressAndCalculateTaxes('shipping'); remember_AccountInformation()"
        >
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && couponValidation && shipAddress.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>

      <div class="form_item_wrapper">
        <label for="addShipAddress" class="form_item_label not_required">{{ getLabelShipping('addressConfirm')}}</label>
        <input
          fixScrollOnMobileiOS
          data-qa="ship-address2"
          id="addShipAddress"
          name="addShipAddress"
          #addShipAddress="ngModel"
          [(ngModel)]="guest.additionalAddressLine"
          type="text"
          class="item_input"
          maxlength="150"
          (change)="remember_AccountInformation()"
        >
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && couponValidation && addShipAddress.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>

      <div class="form_item_wrapper">
        <label for="shipCity" class="form_item_label">{{ getLabelShipping('city')}}</label>

        <input
          fixScrollOnMobileiOS
          data-qa="ship-city"
          id="shipCity"
          name="shipCity"
          #shipCity="ngModel"
          [(ngModel)]="shippingAddress.city"
          type="text"
          class="item_input"
          required
          pattern="{{ addressPattern }}"
          (change)="validate_AddressAndCalculateTaxes('shipping'); remember_AccountInformation()"
        >
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && couponValidation && shipCity.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>

      <div class="form_item_wrapper">
        <label for="shipPostal" class="form_item_label">{{ getLabelShipping('zip')}}</label>

        <input
          fixScrollOnMobileiOS
          data-qa="ship-zip"
          id="shipPostal"
          name="shipPostal"
          #shipPostal="ngModel"
          [(ngModel)]="shippingAddress.postal"
          type="{{ shipCountry.value == 'United States' ? 'tel' : 'text' }}"
          class="item_input"
          required
          pattern="{{ mixedPattern }}"
          (change)="validate_AddressAndCalculateTaxes('shipping'); remember_AccountInformation()"
        >
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && couponValidation && shipPostal.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>

      <div class="form_item_wrapper">
        <label for="shipCountry" class="form_item_label select">{{ getLabelShipping('country')}}</label>
        <select
          fixScrollOnMobileiOS
          data-qa="ship-country"
          [id]="isFacebookApp() ? '' : 'shipCountry'"
          [name]="isFacebookApp() ? 'shipCountry' : 'shipCountry'"
          #shipCountry="ngModel"
          [(ngModel)]="shippingAddress.country"
          type="text"
          class="item_select"
          required
          (change)="validate_AddressAndCalculateTaxes('shipping'); remember_AccountInformation()"
        >
        <ng-container *ngFor="let item of countriesList">
          <option *ngIf="euSpecified || (!euSpecified && item.label !== 'Spain' && item.label !== 'France' && item.label !== 'Germany')" 
                  value="{{ item.label }}" 
                  id="Country-{{ item.label }}"  [innerHTML]="getLabel(item.label) ? getLabel(item.label) : item.label" >
            {{ item.label }}
          </option>
        </ng-container>
        </select>
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && couponValidation && shipCountry.invalid" src="{{ errorImage }}" alt="">
        </div>
      </div>

      <input *ngIf="isFacebookApp()" id="facebookshippingstate"   style="position:absolute; left:-1000px;">
      <input *ngIf="isFacebookApp()" id="FBShippingStateCode" (change)="facebookAppShippingStateChangeHandler()"  style="position:absolute; left:-1000px;" >  
      <input *ngIf="isFacebookApp()" id="FBShippingCountryCode"   style="position:absolute; left:-1000px;" >
      <input *ngIf="isFacebookApp()" id="FBAddressLine1" style="position:absolute; left:-1000px;" >
      <input *ngIf="isFacebookApp()" id="FBAddressLine2" style="position:absolute; left:-1000px;" >
      
      <div class="form_item_wrapper" [ngClass]="displayStateField() ? 'form_item_wrapper' : 'hidden'">
        <label for="shipState" class="form_item_label select">{{ getLabelShipping('state')}}</label>
        <select
          fixScrollOnMobileiOS
          data-qa="ship-state"
          [id]="isFacebookApp() ? '' : 'shipState'"
          [name]="isFacebookApp() ? 'shipState' : 'shipState'"
          #shipState="ngModel"
          [(ngModel)]="shippingAddress.state"
          type="text"
          class="item_select"
          (change)="validate_AddressAndCalculateTaxes('shipping'); remember_AccountInformation()"
        >
          <option value="" disabled>{{ getLabel('choose a state') }}</option>
          <option *ngFor="let item of differentAddressStatesList" value="{{ item.label }}">{{ item.label }}</option>
        </select>
        <div class="form_item_image">
          <img *ngIf="submittedAccountForm && shipState.value === '' && differentAddressStatesList && differentAddressStatesList.length > 0" src="{{ errorImage }}" alt="">
        </div>
      </div>

    </form>
  </div>
</div>
