import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'cadCurrency',
  standalone: false
})
export class CadCurrencyPipe implements PipeTransform {

  transform(value: number, currencyCode: string, locale?: string): string {
    const symbol = getCurrencySymbol(currencyCode, 'narrow', locale);
    return `${symbol}${value.toFixed(2)}`;
  }

}
